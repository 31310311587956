import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Rfq",
    component: () => import("../views/rfq/rfq"),
    // children: [
    //   {
    //     path: "",
    //     redirect: "../views/rfq/pesticides",
    //   }
    // ]
  },
];
const router = new VueRouter({
  // mode: 'history',
  // mode: 'hash',
  // base: "/screen/",
  routes,
});
// router.beforeEach((to, from, next) => {
//   const isLogin = localStorage.userToken ? true : false; //判断是否有Tokend
//   if (to.path == "/infoData") {
//     localStorage.removeItem("userToken"); //空空
//     next();
//     return;
//   }
//   isLogin ? next() : next("/infoData"); //如果有token就登录首页，如果没有就返回登录页面
// });

export default router;
